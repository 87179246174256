
import { useLocation, Link } from "react-router-dom";

const error404Url = '/images/404.png'

function Error404() {
    let location = useLocation();

    return (
        <>
            <div style={{
                background: "linear-gradient(180deg, rgba(136, 201, 202, 1) 0%, rgba(202, 248, 246, 1) 35%, rgba(202, 248, 246, 1) 100%)",
                height: "100vh",
                width: "100vw",
            }}>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    padding: "5px",
                }}>
                    <Link to="/">
                        <img src={error404Url}
                            alt="Pixel Polish Games"
                            style={{

                                padding: "5px",
                                verticalAlign: "top",
                            }}
                        />
                    </Link>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    lineHeight: 0,
                }}>
                    <h2>
                        This URL doesn't exist:
                    </h2>
                </div>
                <div style={{
                    display: "flex",
                    justifyContent: "center",
                    lineHeight: 0,
                }}>
                    <h3>
                        <code>{location.pathname}</code>
                    </h3>
                </div>
            </div>
        </>
    );
}

export default Error404;