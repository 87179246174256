import { useEffect, useState } from 'react';
import { Box, Chip, CircularProgress, Divider } from '@mui/material';

import { Link } from "react-router-dom";

import api from "../api"
import dateFormat from "dateformat";
import { useLocation } from "react-router-dom";

import Screen from '../components/screen'


function NewsContent() {
    const query = new URLSearchParams(useLocation().search);
    const newsId = query.get("newsId");
    const oldId = query.get("oldId");
    const [newsData, setNewsData] = useState(null);

    useEffect(() => {
        const fetchNewsData = async (newsId, oldId) => {
            if (oldId) {
                return await api.get(`news/0?oldId=${oldId}`);
            }

            return await api.get(`news/${newsId}`);
        }

        fetchNewsData(newsId, oldId).then((response) => {
            setNewsData(response.data);
            if (response.data.content) {
                document.getElementById("newsContent").innerHTML = response.data.content;
            }
        })
    }, [newsId, oldId]);

    return (
        <>
            {newsData ?
                <Screen title={newsData.title} >
                    <Box style={{
                        backgroundColor: "#44a4bb",
                        border: "2px solid #3f98a9",
                        borderRadius: "4px",
                        boxShadow: "1px 2px rgba(50, 50, 50, 0.75)",
                        color: "#ffffff",
                        margin: "2px 10px 2px 10px",
                        padding: "5px 5px 5px 5px",
                        textShadow: "2px 2px #222222",
                    }}>
                        Posted by <span style={{ color: "#f8be38", fontWeight: "bold", }}>{newsData.author}</span>&nbsp;
                        on {newsData.postDateTime ? dateFormat(newsData.postDateTime, "dddd, mmmm dS, yyyy") : ''}
                    </Box>

                    <div className="news-content" id="newsContent" />

                    {newsData.tags && newsData.tags.length > 0 ?
                        <>
                            <Divider />

                            <div style={{
                                fontWeight: "bold",
                                padding: "10px 5px 0px 5px",
                            }}>
                                Tags:
                                {(newsData.tags || []).map((tag, i) =>
                                    <span key={i}>
                                        <Link to={`/?tag=${tag.label}`}>
                                            <Chip
                                                color='secondary'
                                                label={tag.label}
                                                style={{
                                                    cursor: "pointer",
                                                    margin: "0px 5px",
                                                    textShadow: "2px 2px #222222",
                                                }}
                                            />
                                        </Link>
                                    </span>)
                                }
                            </div>
                        </>
                        : <span />
                    }
                </Screen>
                :
                <Screen>
                    <CircularProgress />
                </Screen>
            }
        </>
    );
}

export default NewsContent;