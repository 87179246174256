
function FooterBlock() {
    return (
        <footer style={{
            margin: "0 auto",
            padding: "15px",
            textAlign: "center",
            width: "100%",
        }}>
            &copy; Pixel Polish Games {new Date().getFullYear()} All Rights Reserved
        </footer>
    );
}

export default FooterBlock;