import { useEffect } from 'react';
import { Box, Card, Chip, Divider } from '@mui/material';

import { Link } from "react-router-dom";

import dateFormat from "dateformat";


function NewsContent(props) {
    useEffect(() => {
        document.getElementById(`newsContent-${props.id}`).innerHTML = props.content;
    }, [props]);

    return (
        <div style={{ padding: "10px" }} >

            <Card raised style={{
                backgroundColor: "#ffffff",
                margin: "0 auto",
                padding: "15px 15px",
                textAlign: "center",
                verticalAlign: "middle",
            }}>

                <div style={{
                    textAlign: "left",
                }}>
                    <Link to={props.link} style={{
                        color: '#000000',
                        textDecoration: "none",
                        textShadow: "none",
                    }}>
                        <h1 className="news-post">{props.title}</h1>
                    </Link>
                </div>

                <Box style={{
                    backgroundColor: "#44a4bb",
                    border: "2px solid #3f98a9",
                    borderRadius: "4px",
                    boxShadow: "1px 2px rgba(50, 50, 50, 0.75)",
                    color: "#ffffff",
                    margin: "2px 10px 2px 10px",
                    padding: "5px 5px 5px 5px",
                    textShadow: "2px 2px #222222",
                }}>
                    Posted by <span style={{ color: "#f8be38", fontWeight: "bold", }}>{props.author}</span>&nbsp;
                    on {dateFormat(props.dateTime, "dddd, mmmm dS, yyyy")}
                </Box>

                <div id={`newsContent-${props.id}`} className="news-content" />

                <section style={{
                    margin: "0px 15px",
                    padding: "5px",
                }}>
                    <Link to={props.link}>
                        Read More...
                    </Link>
                </section>

                {props.tags && props.tags.length > 0 ?
                    <>
                        <Divider />

                        <div style={{
                            fontWeight: "bold",
                            padding: "10px 5px 0px 5px",
                        }}>
                            Tags:
                            {(props.tags || []).map((tag, i) =>
                                <Chip
                                    color='secondary'
                                    label={tag.label}
                                    key={i}
                                    onClick={() => props.tagSetter(tag.label)}
                                    component={Link}
                                    to={`/?tag=${tag.label}`}
                                    style={{
                                        margin: "0px 5px",
                                        textShadow: "2px 2px #222222",
                                    }}
                                />)}
                        </div>
                    </>
                    : <span />
                }
            </Card>
        </div >
    );
}

export default NewsContent;
