import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

import { Z_INDEX } from "../utils/constants"

function ConfirmDialog(props) {
    async function onClose(e) {
        props.onClose();
    }

    async function onSubmit(e) {
        e.preventDefault();
        props.onSubmit();
    }

    return (
        <Dialog
            open={props.open}
            disableScrollLock={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.onClose(event, reason);
                }
            }}
            style={{ zIndex: Z_INDEX.MODAL + 1 }}>

            <form onSubmit={onSubmit}>
                <DialogTitle style={{
                    fontWeight: "bold",
                }}>
                    {props.title}
                </DialogTitle>
                <DialogContent>
                    {props.text}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" type="submit">Ok</Button>
                    <Button variant="contained" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default ConfirmDialog;
