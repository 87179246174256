import { useEffect, useState } from 'react';
import {
    Pagination,
    PaginationItem
} from '@mui/material';

import { Link, useHistory, useLocation } from "react-router-dom";

import api from "../api"
import NewsStub from '../components/news-stub'


function News() {
    // Must be insync with server
    const NEWS_PER_PAGE = 15;

    const query = new URLSearchParams(useLocation().search);
    const pageProp = query.get("page");
    const tagProp = query.get("tag");

    const [page, setPage] = useState(pageProp || 1);
    const [tag, setTag] = useState(tagProp);
    const [news, setNews] = useState([]);
    const [newsCount, setNewsCount] = useState(0);

    const history = useHistory()
    useEffect(() => {
        // Monitor usage to ensure we don't run async while unmounted
        let isProcessing = true;

        async function fetchNewsCount() {
            await api.get(`news_count${urlParameterizer(page, tag)}`).then((response) => {
                setNewsCount(response.data);
            });
        }

        async function fetchNews() {
            await api.get(`news${urlParameterizer(page, tag)}`).then((response) => {
                setNews(response.data);
            });
        }

        history.listen((location) => {
            // When the location has been adjusted to the base url
            // (by clicking the main news button) clear out existing state
            if (isProcessing && !location.search) {
                setTag(null);
                setPage(1);
            }

        })

        fetchNewsCount()
        fetchNews()
        return () => (isProcessing = false)
    }, [history, page, tag]);

    const urlParameterizer = (page, tag) => {
        let url = "";
        if (page > 1) {
            url = `${url}?page=${page}`;
        }
        if (tag) {
            const notation = page > 1 ? "&" : "?";
            url = `${url}${notation}tag=${tag}`;
        }
        return url;
    }

    const handlePageChange = (e, page) => {
        setPage(page);
    }

    const handleSetTag = (tag) => {
        setPage(1);
        setTag(tag);
    }

    return (
        <>
            {
                news.map((news_data) => {
                    return (
                        <NewsStub
                            key={news_data._id}
                            id={news_data._id}
                            title={news_data.title}
                            author={news_data.author}
                            dateTime={news_data.postDateTime}
                            link={`/news?newsId=${news_data._id}`}
                            tags={news_data.tags}
                            content={news_data.short_content}
                            tagSetter={handleSetTag}
                        />
                    );
                })
            }

            {
                newsCount > 0 ?
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                    }}>
                        <Pagination
                            count={Math.ceil(newsCount / NEWS_PER_PAGE)}
                            color="secondary"
                            page={page}
                            onChange={handlePageChange}
                            size="large"
                            style={{
                                display: "block",
                                padding: "10px",
                            }}

                            renderItem={(item) => {
                                return (<PaginationItem
                                    component={Link}
                                    to={urlParameterizer(item.page, tag)}
                                    {...item}
                                />);
                            }

                            }
                        />
                    </div>
                    : <span />
            }

        </>
    );
}

export default News;