
export const Z_INDEX = {
    MOBILE_STEPPER: 1000,
    SPEED_DIAL: 1050,
    APP_BAR: 1100,
    DRAWER: 1200,
    MODAL: 1300,
    SNACKBAR: 1400,
    TOOLTIP: 1500,
};
