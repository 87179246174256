import ImageGallery from 'react-image-gallery';

import Screen from '../../components/screen'

import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

const claireImageUrl = '/games/lootgrinder/claire_victory.gif';
const screenshots = [
    {
        original: "/games/lootgrinder/screenshot1.png",
        thumbnail: "/games/lootgrinder/screenshot1.png",
    },
    {
        original: "/games/lootgrinder/screenshot2.png",
        thumbnail: "/games/lootgrinder/screenshot2.png",
    },
    {
        original: "/games/lootgrinder/screenshot3.png",
        thumbnail: "/games/lootgrinder/screenshot3.png",
    },
    {
        original: "/games/lootgrinder/screenshot4.png",
        thumbnail: "/games/lootgrinder/screenshot4.png",
    },
]


function LootGrinder() {

    return (
        <>
            <Screen title="Loot Grinder">
                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                }}>
                    Loot Grinder is a retro RPG inspired by the 16-bit era classics.
                    <div id="floated" style={{
                        float: "right",
                        padding: "15px",
                        width: "70px",
                    }}>
                        <img src={claireImageUrl} alt="Victory" />
                    </div>
                    This game is all about doing battle and unlocking new classes and abilities.
                    Do not look for boring paragraphs of text or generic story lines here.
                    If all you want is to dive head first into some battles, this game is for you.
                    Battle monsters, unlock classes, weapons and spells, upgrade your heroes, and collect loot.

                </div>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                    padding: "15px",
                }}>
                    <div style={{
                        textAlign: "left",
                    }}>
                        <ul>
                            <li>Hero Customization- Customize your team at anytime by assigning job classes that you have unlocked.  These job classes provide new abilities the longer you level them up.</li>
                            <li>Monster Varieties- Unique monsters are found in a variety of settings.  Travel to a destination long enough to unlock special Boss battles.  If your heroes are strong enough to win, they will be rewarded.</li>
                            <li>Nostalgia- Uniquely themed character and monster sprites designed to give a feeling of those 16-bit days.</li>
                        </ul>
                    </div>
                </div>

                <div style={{
                    margin: "0 auto",
                    padding: "5px",
                    width: "100%",
                    maxWidth: "800px",
                }}>
                    <ImageGallery
                        items={screenshots}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false} />
                </div>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                    padding: "15px",
                }}>
                    <h3>Purchase</h3>
                    Loot Grinder was available on Windows Phone 7,8 as well as XBox Indie Games.

                    <div style={{
                        textAlign: "left",
                    }}>
                        <ul>
                            <li><a href="https://www.microsoft.com/en-us/store/p/loot-grinder/9nblggh0d8dn?rtc=1" alt="Loot Grinder on Windows Phone">Windows Phone</a>($0.99)</li>
                            <li>Xbox (Microsoft has discontinued this marketplace)</li>
                        </ul>
                    </div>
                </div>

                <h3>Promotional</h3>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                    padding: "15px",
                }}>
                    <div style={{
                        textAlign: "left",
                    }}>
                        <ul>
                            <li><a href="/games/lootgrinder/lootgrinder_pixelpolishgames.zip" alt="Loot Grinder Media Package">Media Package</a></li>
                        </ul>
                    </div>
                </div>
            </Screen>
        </>
    );
}


export default LootGrinder;