import { Button, Tooltip } from '@mui/material';
import { IconContext } from "react-icons";

function TooltipButton(props) {
    return (
        <Tooltip title={props.title}>
            {props.color ?
                <Button variant="contained" style={{
                    padding: props.padding,
                    margin: props.margin,
                    minWidth: '32px',
                    color: props.fillColor,
                    backgroundColor: props.color,
                }}>
                    <props.icon size={props.size} onClick={props.onClick} />
                </Button>
                :
                <Button variant="text" style={{
                    padding: props.padding,
                    margin: props.margin,
                    minWidth: '32px',
                }}>
                    <IconContext.Provider value={{ color: props.fillColor }}>
                        <div>
                            <props.icon size={props.size} onClick={props.onClick} />
                        </div>
                    </IconContext.Provider>
                </Button>
            }
        </Tooltip >
    );
}

TooltipButton.defaultProps = {
    padding: 2,
    margin: 2,
    size: "24",
    title: "",
    fillColor: "primary",
    onClick: () => { },
}

export default TooltipButton;