import { useCallback, useEffect, useState } from 'react';
import {
    Chip,
    Container,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
} from '@mui/material';

import { BsFillPlusSquareFill } from 'react-icons/bs';
import { RiDeleteBin7Fill } from 'react-icons/ri';
import { shallowEqual, useSelector } from "react-redux";

import api from "../../api"
import { getUser } from "../../stores/user/selectors"
import AlertStack from '../../components/alert-stack';
import ConfirmDialog from '../../dialogs/confirm-dialog';
import Screen from '../../components/screen'
import TooltipButton from '../../components/tooltip-button'


function TagsList() {
    const [tags, setTags] = useState([]);
    const [page, setPage] = useState(0);
    const [tagsPerPage, setTagsPerPage] = useState(15);
    const [confirmDeleteText, setConfirmDeleteText] = useState("");
    const [confirmDeleteId, setConfirmDeleteId] = useState(0);
    const [newTag, setNewTag] = useState('');
    const [alertText, setAlertText] = useState("");
    const [alertType, setAlertType] = useState("success");

    const user = useSelector(getUser, shallowEqual)

    const fetchTags = useCallback(async () => {
        await api.get(`tags`).then((response) => {
            setTags(response.data || []);
        }).catch(() => {
            showAlert("error", "Error loading tags");
        })
    }, [])

    useEffect(() => {
        fetchTags();
    }, [fetchTags]);

    const showAlert = (type, text) => {
        setAlertType(type);
        setAlertText(text);
    }

    const handleNewTagType = () => {
        // clear any alerts
        showAlert("success", "");

        if (!newTag) {
            return;
        }

        let data = {
            label: newTag
        };

        api.post(`admin/tags`, data).then((response) => {
            if (response.status === 200) {
                fetchTags();
                showAlert("success", "Saved");
            }
        }).catch((error) => {
            if (error.response && error.response.status === 412) {
                showAlert("warning", "Duplicate Detected")
            }
            else {
                console.warn(error);
                showAlert("error", "Error Submitting...");
            }
        });

        // Clear out text entry for next new tag
        setNewTag('');
    }

    const handleDelete = (id) => {
        // Had trouble with the dialog being a single instance, where the last news entry
        // would always be the one that got deleted, when we'd pass these properties into
        // the html, so instead, set all the properties here upon confirmation.
        setConfirmDeleteId(id);
        const label = tags.find(tagsData => tagsData._id === id).label;
        setConfirmDeleteText(`Are you sure you want to delete: ${label}`);
    }

    const handleDeleteConfirmed = async () => {
        setConfirmDeleteId(0);
        await api.delete(`admin/tags/${confirmDeleteId}`).then((response) => {
            fetchTags();
        }).catch((error) => {
            console.warn(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeTagsPerPage = (event) => {
        setTagsPerPage(event.target.value);
        setPage(0);
    };

    const columns = [
        {
            key: 'label',
            label: 'Label',
            minWidth: "500px",
            format: (tag) => {
                return (<Chip
                    color='secondary'
                    label={tag}
                    key={tag}
                    style={{
                        margin: "0px 1px",
                        textShadow: "2px 2px #222222",
                    }}
                />);
            }
        },
        {
            key: 'buttons',
            label: '',
            minWidth: 80,
        }
    ];

    return (
        <Screen title="Tags List">
            {
                user && user.permissions.includes('news') ?
                    <Container>
                        <AlertStack open={alertText !== ""} onClose={() => setAlertText("")} text={alertText} type={alertType} />

                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">

                                            <Stack
                                                direction="row"
                                                justifyContent="flex-end"
                                                alignItems="center"
                                                spacing={2}
                                            >

                                                <Stack
                                                    alignItems="flex-start"
                                                    justifyContent="flex-start"
                                                    spacing={2}
                                                >
                                                    <span style={{
                                                        fontWeight: "bold",
                                                    }}>
                                                        Add new tag
                                                    </span>

                                                    <TextField
                                                        margin="dense"
                                                        label="Tag"
                                                        type="text"
                                                        variant="standard"
                                                        value={newTag}
                                                        onChange={(e) => setNewTag(e.target.value)}
                                                        // onChange={e => setNewTag(e.target.value)}
                                                        style={{
                                                            width: "150px",
                                                        }}
                                                    />
                                                </Stack>

                                                <span>
                                                    <TooltipButton
                                                        icon={BsFillPlusSquareFill}
                                                        title="New Tag"
                                                        color="#44a4bb"
                                                        padding={5}
                                                        margin={8}
                                                        onClick={handleNewTagType}
                                                    />
                                                </span>
                                            </Stack>

                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.key}
                                                align={column.align}
                                                style={{ fontWeight: "bold", minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tags
                                        .slice(page * tagsPerPage, page * tagsPerPage + tagsPerPage)
                                        .map((tagsData) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={tagsData._id}>
                                                    {columns.map((column) => {
                                                        if (column.key === "buttons") {
                                                            return (
                                                                <TableCell key={column.key} align={column.align}>
                                                                    <TooltipButton icon={RiDeleteBin7Fill} title="Delete" color="#44a4bb"
                                                                        onClick={() => handleDelete(tagsData._id)}
                                                                    />

                                                                    <ConfirmDialog
                                                                        title="Delete"
                                                                        text={confirmDeleteText}
                                                                        open={confirmDeleteId !== 0}
                                                                        onClose={() => setConfirmDeleteId(0)}
                                                                        onSubmit={handleDeleteConfirmed}
                                                                    />
                                                                </TableCell>
                                                            )
                                                        }
                                                        else {
                                                            const value = tagsData[column.key];
                                                            return (
                                                                <TableCell key={column.key} align={column.align}>
                                                                    {column.format
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}

                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </ TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[15, 30, 50, 100]}
                            component="div"
                            count={tags.length}
                            rowsPerPage={tagsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeTagsPerPage}
                        />
                    </Container >
                    : <div>Unauthorized</div>
            }
        </Screen >
    );
}

export default TagsList;