import { CLEAR_USER, SET_USER } from "./types";

const defaults = {
    user: null,
}

function reducer(state = defaults, action) {
    switch (action.type) {
        case SET_USER:
            const user_data = action.user_data;
            return {
                ...state,
                user: { ...user_data },
            };
        case CLEAR_USER:
            return {
                ...defaults,
            };
        default:
            return state;
    }
}

export default reducer;
