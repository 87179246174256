import { useState } from 'react';
import { AppBar, Box, Button, Toolbar } from '@mui/material';

import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { BiLogInCircle } from 'react-icons/bi';
import { FaUserCircle } from 'react-icons/fa';
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";

import api from "../api"
import LoginDialog from "../dialogs/login-dialog"
import DropMenu from "./drop-menu"
import TooltipButton from "./tooltip-button"
import { setUser, clearUser } from "../stores/user/actions"
import { getUser } from "../stores/user/selectors"
import { Z_INDEX } from "../utils/constants"


function MenuBar() {
    const user = useSelector(getUser, shallowEqual);
    const dispatch = useDispatch()

    const [showLoginDialog, setShowLoginDialog] = useState(false);
    const [showLoginError, setShowLoginError] = useState(false);

    const handleLoginOpen = () => {
        setShowLoginDialog(true);
    };

    const handleLoginClose = () => {
        setShowLoginDialog(false);
        setShowLoginError(false);
    };

    const handleSendLogin = async (data) => {
        setShowLoginError(false);
        await api.post("login", data).then((response) => {
            dispatch(setUser(response.data));
            handleLoginClose();
        }).catch((error) => {
            console.warn(error);
            setShowLoginError(true);
        });
    };

    return (
        // There is an existing bug in Menu which causes the scrollbar to vanish and mess with the width of the main
        // window which causes some components to move around. Disable the `disableScrollLock` on Menu and watch for
        // scroll wheel movements to also auto close the menu, otherwise it'll stay around as the screen scrolls.
        <AppBar
            style={{
                background: "linear-gradient(180deg, rgba(72,176,202,1) 0%, rgba(31,107,125,1) 100%)",
                borderBottomStyle: "solid",
                borderWidth: "2px",
                borderColor: "#80cace",
                height: "60px",
                position: "static",
            }}>
            <Toolbar>

                <Box display='flex' flexGrow={1}>
                    <Link to="/" style={{ textDecoration: "none" }}>
                        <Button className={"nav-button"}
                            style={{ zIndex: Z_INDEX.MODAL + 1 }}>
                            News
                        </Button>
                    </Link>

                    <DropMenu id="game-menu" items={[
                        { text: "Loot Grinder", url: "/games/loot-grinder" },
                        { text: "Monster Escape", url: "/games/monster-escape" },

                    ]}>
                        <Link to="/games" style={{ textDecoration: "none" }}>
                            <Button
                                className={"nav-button"}
                                style={{ zIndex: Z_INDEX.MODAL + 1 }}
                            >
                                Games
                            </Button>
                        </Link>
                    </ DropMenu>

                    <Link to="/about" style={{ textDecoration: "none" }}>
                        <Button className={"nav-button"}
                            style={{ zIndex: Z_INDEX.MODAL + 1 }}>
                            About
                        </Button>
                    </Link>
                </Box>

                {user === undefined || user === null ?
                    <span>
                        <TooltipButton
                            icon={BiLogInCircle}
                            fillColor="#ffffff"
                            title="Login"
                            onClick={handleLoginOpen} />
                        <LoginDialog
                            open={showLoginDialog}
                            showError={showLoginError}
                            onClose={handleLoginClose}
                            onSubmit={handleSendLogin} />
                    </span>
                    : <span>
                        <DropMenu id="user-menu" items={[
                            { text: "News", url: "/admin/news" },
                            { text: "Tags", url: "/admin/tags" },
                            { text: "Logout", onClick: () => { dispatch(clearUser()); } },
                        ]}>
                            <Link to="/admin/news">
                                <Button
                                    className={"nav-button"}
                                    style={{ zIndex: Z_INDEX.MODAL + 1 }}
                                >
                                    <IconContext.Provider value={{ color: '#ffffff' }}>
                                        <FaUserCircle size="24" />
                                    </IconContext.Provider>
                                </Button>
                            </Link>
                        </ DropMenu>
                    </span>
                }
            </Toolbar>
        </AppBar >
    );
}

export default MenuBar;
