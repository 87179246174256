import { Card } from '@mui/material';



function Screen(props) {
    return (
        <div style={{ padding: "10px" }}>
            <div style={{
                textAlign: "center",
                textOverflow: 'ellipsis'
            }}>
                <h2>{props.title}</h2>
            </div>

            <Card raised style={{
                backgroundColor: "#ffffff",
                margin: "0 auto",
                padding: "15px 15px",
                textAlign: "center",
                verticalAlign: "middle",
            }}>
                {props.children}
            </Card >
        </div >
    );
}

export default Screen;
