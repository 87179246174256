import React from 'react';
import ReactDOM from 'react-dom';

import '@fontsource/roboto';
import CssBaseline from '@mui/material/CssBaseline';
import { StyledEngineProvider, ThemeProvider, createTheme } from '@mui/material/styles';
import { Provider } from 'react-redux'

import './index.css';
import App from './app';
import store from './stores/store'

import reportWebVitals from './reportWebVitals';

const theme = createTheme({
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        }
      },
    },
  },
  palette: {
    type: 'light',
    primary: {
      main: '#f8be38',
      contrastText: '#ffffff',
    },
    secondary: {
      main: '#44a4bb',
      contrastText: '#ffffff',
    },
  },
  typography: {
    button: {
      fontWeight: 1000,
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <ThemeProvider theme={theme}>
      <StyledEngineProvider injectFirst>
        <Provider store={store}>
          <App />
        </Provider>
      </StyledEngineProvider>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
