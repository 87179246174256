const axios = require('axios');

const API_URI = process.env.REACT_APP_API_URI

const api = {
    async get(path) {
        return await axios.get(`${API_URI}${path}`)
            .then((response) => {
                return response;
            })
    },

    async post(path, data) {
        return await axios.post(`${API_URI}${path}`, data)
            .then((response) => {
                return response;
            })
    },

    async put(path, data) {
        return await axios.put(`${API_URI}${path}`, data)
            .then((response) => {
                return response;
            })
    },

    async delete(path) {
        return await axios.delete(`${API_URI}${path}`)
            .then((response) => {
                return response;
            })
    }
}

export default api;
