import { Redirect, Route, Switch } from "react-router-dom";

import About from '../screens/about'
import Error404 from '../screens/error-404'
import Games from '../screens/games'
import HeaderBlock from '../components/header-block'
import FooterBlock from '../components/footer-block'
import LootGrinder from '../screens/games/loot-grinder';
import MenuBar from '../components/menu-bar'
import News from '../screens/news';
import NewsContent from '../screens/news-content';
import NewsList from '../screens/admin/news-list';
import PostNews from '../screens/admin/post-news';
import MonsterEscape from '../screens/games/monsterescape';
import TagsList from '../screens/admin/tags-list';


function Main() {

    return (
        <div style={{
            backgroundColor: "#eeeeee",
            minHeight: "100vh",
        }}>
            <Switch>
                <Redirect from="/node/:id" to="/news?oldId=:id" />

                <Route path="/admin/news">
                    <HeaderBlock />
                    <MenuBar />
                    <NewsList />
                    <FooterBlock />
                </Route>
                <Route path="/admin/post-news">
                    <HeaderBlock />
                    <MenuBar />
                    <PostNews />
                    <FooterBlock />
                </Route>
                <Route path="/admin/tags">
                    <HeaderBlock />
                    <MenuBar />
                    <TagsList />
                    <FooterBlock />
                </Route>
                <Route path="/about">
                    <HeaderBlock />
                    <MenuBar />
                    <About />
                    <FooterBlock />
                </Route>
                <Route exact path="/games">
                    <HeaderBlock />
                    <MenuBar />
                    <Games />
                    <FooterBlock />
                </Route>
                <Route path="/games/loot-grinder">
                    <HeaderBlock />
                    <MenuBar />
                    <LootGrinder />
                    <FooterBlock />
                </Route>
                <Route path="/games/monster-escape">
                    <HeaderBlock />
                    <MenuBar />
                    <MonsterEscape />
                    <FooterBlock />
                </Route>
                <Route path="/news">
                    <HeaderBlock />
                    <MenuBar />
                    <NewsContent />
                    <FooterBlock />
                </Route>
                <Route exact path="/">
                    <HeaderBlock />
                    <MenuBar />
                    <News />
                    <FooterBlock />
                </Route>
                <Route path="*">
                    <Error404 />
                </Route>
            </Switch>
        </div>
    );
}

export default Main;