import { List, Divider } from '@mui/material';

import { Link } from "react-router-dom";

import Screen from '../components/screen'
import TeamCard from '../components/team-card';

const jamesImageUrl = '/images/james.png';


function About() {

    const jamesDetails = "I've worked in game development professionally for a few years and now do it as a hobby. I enjoy developing worlds and spending time with creative people.  Seeing my creations come to life is one of the most satisfying types of software development I've experienced.";

    return (
        <Screen title="About">
            <p style={{
                padding: "5px",
            }}>
                <b>
                    Pixel Polish Games is an independent game studio based in Sioux Falls, SD. We've been making games for
                    a number of years some of which are listed in the <Link to="/games"> Games </Link> page.
                </b>
            </p>
            <Divider style={{
                padding: "15px",
            }} />

            <br />
            <h3>Contributors</h3>
            <List sx={{
                width: '100%',
            }}>
                <TeamCard image={jamesImageUrl} name="James Kirk" roles="Programmer, Designer" projects="Loot Grinder, Monster Escape" details={jamesDetails} />
                <Divider />
                <TeamCard
                    name="Seung Hyun Ji"
                    roles="Artist"
                    projects="Monster Escape"
                    details={<a href='https://www.etsy.com/shop/SingDoodle' target="_blank" rel="noreferrer">Art of Sing</a>}
                />
                <Divider />
                <TeamCard
                    name="David Yingling"
                    roles="Audio"
                    projects="Monster Escape"
                    details={<a href='http://www.djyaudio.com/' target="_blank" rel="noreferrer">DJYaudio</a>}
                />
                <Divider />
            </List>
        </Screen>
    );
}

export default About;