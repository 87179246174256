import { useState } from 'react';
import { Alert, Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@mui/material';

import { Z_INDEX } from "../utils/constants"

function LoginDialog(props) {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();

    async function onClose(e) {
        setUsername(null);
        setPassword(null);
        props.onClose();
    }

    async function onSubmit(e) {
        e.preventDefault();
        props.onSubmit({ username, password });
    }

    return (
        <Dialog
            open={props.open}
            disableScrollLock={true}
            onClose={(event, reason) => {
                if (reason !== 'backdropClick') {
                    props.onClose(event, reason);
                }
            }}
            style={{ zIndex: Z_INDEX.MODAL + 1 }}>
            <form onSubmit={onSubmit}>
                <DialogTitle style={{
                    fontWeight: "bold",
                }}>
                    Login
                </DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="pp_username"
                        label="User"
                        type="text"
                        fullWidth
                        variant="standard"
                        onChange={e => setUsername(e.target.value)}
                    />
                    <TextField
                        margin="dense"
                        id="pp_password"
                        label="Password"
                        type="password"
                        fullWidth
                        variant="standard"
                        onChange={e => setPassword(e.target.value)}
                    />
                    {
                        props.showError ?
                            <Alert severity="error">Incorrect User or Password</Alert>
                            : <span />
                    }
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" type="submit">Login</Button>
                    <Button variant="contained" onClick={onClose}>Cancel</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
}

export default LoginDialog;
