import ImageGallery from 'react-image-gallery';

import Screen from '../../components/screen'

import "../../../node_modules/react-image-gallery/styles/css/image-gallery.css";

const screenshots = [
    {
        original: "/games/monsterescape/screenshot1.png",
        thumbnail: "/games/monsterescape/screenshot1.png",
    },
    {
        original: "/games/monsterescape/screenshot2.png",
        thumbnail: "/games/monsterescape/screenshot2.png",
    },
    {
        original: "/games/monsterescape/screenshot3.png",
        thumbnail: "/games/monsterescape/screenshot3.png",
    },
    {
        original: "/games/monsterescape/screenshot4.png",
        thumbnail: "/games/monsterescape/screenshot4.png",
    },
    {
        original: "/games/monsterescape/screenshot5.png",
        thumbnail: "/games/monsterescape/screenshot5.png",
    },
    {
        original: "/games/monsterescape/screenshot6.png",
        thumbnail: "/games/monsterescape/screenshot6.png",
    },
]


function MonsterEscape() {

    return (
        <>
            <Screen title="Monster Escape">
                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                }}>
                    Monster Escape is an action-puzzle hybrid where players navigate their monsters safely through levels.
                    Along the way they must make a pathway with puzzle pieces to connect the landscape and avoid a variety of enemies.
                    Each level requires a new approach while still allowing multiple solutions.
                </div>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                    padding: "15px",
                }}>
                    <div style={{
                        textAlign: "left",
                    }}>
                        <ul>
                            <li>4 Worlds with 10 levels each, totaling 40 different challenges</li>
                            <li>4 Controllable monsters and 12 different enemies</li>
                        </ul>
                    </div>
                </div>

                <div style={{
                    margin: "0 auto",
                    padding: "5px",
                    width: "100%",
                    maxWidth: "800px",
                }}>
                    <ImageGallery
                        items={screenshots}
                        showFullscreenButton={false}
                        showPlayButton={false}
                        showNav={false} />
                </div>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                    padding: "15px",
                }}>
                    <h3>Purchase</h3>
                    Monster Escape was available on Windows Phone 7 as well as XBox Indie Games.

                    <div style={{
                        textAlign: "left",
                    }}>
                        <ul>
                            <li>Windows Phone(Microsoft has discontinued this marketplace)</li>
                            <li>Xbox (Microsoft has discontinued this marketplace)</li>
                        </ul>
                    </div>
                </div>

                <h3>Promotional</h3>

                <div style={{
                    margin: "0 auto",
                    textAlign: "center",
                    width: "80%",
                    padding: "15px",
                }}>
                    <div style={{
                        textAlign: "left",
                    }}>
                        <ul>
                            <li><a href="/games/monsterescape/monsterescape_pixelpolishgames.zip" alt="Monster Escape Media Package">Media Package</a></li>
                        </ul>
                    </div>
                </div>
            </Screen>
        </>
    );
}


export default MonsterEscape;