import { useEffect, useState } from 'react';
import {
    Button,
    Checkbox,
    Chip,
    CircularProgress,
    Container,
    Divider,
    FormControlLabel,
    MenuItem,
    OutlinedInput,
    Select,
    TextField
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import DateTimePicker from '@mui/lab/DateTimePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import plugins from 'suneditor/src/plugins'
import SunEditor from 'suneditor-react';
import { shallowEqual, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import api from "../../api"
import { getUser } from "../../stores/user/selectors"
import Screen from '../../components/screen'
import AlertStack from '../../components/alert-stack';

import 'suneditor/dist/css/suneditor.min.css';

const API_URI = process.env.REACT_APP_API_URI


function PostNews() {
    const query = new URLSearchParams(useLocation().search);
    const newsId = query.get("newsId");

    const user = useSelector(getUser, shallowEqual);

    const [newsData, setNewsData] = useState(null);
    const [tagList, setTagList] = useState(null);
    const [shortContent, setShortContent] = useState("");
    const [content, setContent] = useState("");
    const [title, setTitle] = useState("");
    const [postDateTime, setPostDateTime] = useState(new Date());
    const [published, setPublished] = useState(false);
    const [alertText, setAlertText] = useState("");
    const [alertType, setAlertType] = useState("success");
    const [tags, setTags] = useState([]);

    useEffect(() => {
        async function fetchTags() {
            await api.get(`tags`).then((response) => {
                setTagList(response.data || []);
            }).catch(() => {
                showAlert("error", "Error loading tags");
            })
        }

        async function fetchNewsData(newsId) {
            await api.get(`admin/news/${newsId}`).then((response) => {
                if (response.data === null) {
                    return;
                }

                setNewsData({ ...response.data });
                setTitle(response.data.title);
                setPostDateTime(response.data.postDateTime);
                setPublished(response.data.published);
                setTags(response.data.tags)
            }).catch(() => {
                showAlert("error", "Error loading news");
            })
        }

        if (!tagList) {
            fetchTags();
        }

        if (newsId && !newsData) {
            fetchNewsData(newsId)
        }
    }, [newsId, newsData, tagList]);

    const showAlert = (type, text) => {
        setAlertType(type);
        setAlertText(text);
    }

    const handleContentChange = (changedContent) => {
        setContent(changedContent);
        document.getElementById("target").innerHTML = changedContent;
    }

    const handleShortContentChange = (changedContent) => {
        setShortContent(changedContent);
    }

    const handleSave = () => {
        // clear any alerts
        showAlert("success", "");

        let data = {
            title: title,
            author: user.user.charAt(0).toUpperCase() + user.user.slice(1),
            postDateTime: postDateTime,
            published: published,
            content: content,
            short_content: shortContent,
            tags: tags,
        };

        if (!newsData || newsData._id === undefined || newsData._id === null) {
            api.post(`admin/news`, data).then((response) => {
                if (response.status === 200) {
                    setNewsData({ ...data, _id: response.data });
                    showAlert("success", "Saved");
                }
            }).catch((error) => {
                console.warn(error);
                showAlert("error", "Error Submitting...");
            });
        }
        else {
            api.put(`admin/news/${newsData._id}`, data).then((response) => {
                if (response.status === 200) {
                    showAlert("success", "Updated");
                }
            }).catch((error) => {
                console.warn(error);
                showAlert("error", "Error Submitting...");
            });
        }
    }

    const handleTagAdd = (e) => {
        if (tags.find(tag => tag.label === e.target.value)) {
            return;
        }

        const newTags = tags.map(tag => tag);
        newTags.push(tagList.find(tag => tag.label === e.target.value))

        setTags(newTags);
    }

    const handleDeleteTag = (tagToDelete) => {
        setTags(tags.filter((tag) => tag._id !== tagToDelete._id))
    }

    const handlePublishChange = (content) => {
        setPublished(!published);
    }

    const currentContents = () => {
        return newsData ? newsData.content : "";
    }

    const currentShortContents = () => {
        return newsData ? newsData.short_content : "";
    }

    const handleImageUploadError = (error, result) => {
        showAlert("error", "Failed to upload file.");
    }

    return (
        <Screen title="Post News">
            <AlertStack open={alertText !== ""} onClose={() => setAlertText("")} text={alertText} type={alertType} />

            {user && user.permissions.includes('news') ?
                <Container>
                    {newsId && newsData === null ? <CircularProgress /> :
                        <>
                            <TextField
                                autoFocus={newsData === null ? true : false}
                                value={title}
                                margin="dense"
                                id="title"
                                label="Title"
                                type="text"
                                style={{ marginBottom: "25px" }}
                                fullWidth
                                variant="standard"
                                onChange={e => setTitle(e.target.value)}
                            />

                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                <DateTimePicker
                                    label="Publish Date"
                                    value={postDateTime}
                                    onChange={setPostDateTime}
                                    renderInput={(params) => <TextField {...params}
                                        style={{ marginBottom: "25px" }} />}
                                />
                            </LocalizationProvider>

                            <br />
                            Front Page
                            <SunEditor
                                setContents={currentShortContents()}
                                onChange={handleShortContentChange}
                                onImageUploadError={handleImageUploadError}
                                setOptions={{
                                    height: 150,
                                    imageUploadHeader: { 'Authorization': user.token },
                                    imageUploadUrl: `${API_URI}editor/upload`,
                                    plugins: plugins,
                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['font', 'fontSize', 'formatBlock'],
                                        ['paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                        ['fontColor', 'hiliteColor', 'textStyle'],
                                        ['removeFormat'],
                                        ['table', 'link', 'image'],
                                    ]
                                }}
                            />

                            <br />
                            Content
                            <SunEditor
                                autoFocus={newsData === null ? false : true}
                                setContents={currentContents()}
                                onChange={handleContentChange}
                                onImageUploadError={handleImageUploadError}
                                setOptions={{
                                    height: 400,
                                    imageUploadHeader: { 'Authorization': user.token },
                                    imageUploadUrl: `${API_URI}editor/upload`,
                                    plugins: plugins,
                                    buttonList: [
                                        ['undo', 'redo'],
                                        ['font', 'fontSize', 'formatBlock'],
                                        ['paragraphStyle', 'blockquote'],
                                        ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
                                        ['fontColor', 'hiliteColor', 'textStyle'],
                                        ['removeFormat'],
                                        '/', // Line break
                                        ['outdent', 'indent'],
                                        ['align', 'horizontalRule', 'list', 'lineHeight'],
                                        ['table', 'link', 'image', 'video', 'audio' /** ,'math' */], // You must add the 'katex' library at options to use the 'math' plugin.
                                        /** ['imageGallery'] */ // You must add the "imageGalleryUrl".
                                        ['fullScreen', 'showBlocks', 'codeView', 'preview'],
                                        [/**'print'**/],
                                        // ['save', 'template']
                                    ]
                                }}
                            />

                            {!tagList || tagList.length === 0 ? <span /> :
                                <>
                                    <br />
                                    <div>
                                        <Select
                                            label="Tags"
                                            onChange={handleTagAdd}
                                            input={<OutlinedInput label="Name" />}
                                            value=""
                                            style={{
                                                width: "150px",
                                            }}
                                        >
                                            {tagList.map((tag) => {
                                                return (
                                                    <MenuItem key={tag._id} value={tag.label}>{tag.label}</MenuItem>
                                                );
                                            }
                                            )}
                                        </Select>
                                        <span style={{
                                            margin: "4rem",
                                            backgroundColor: "red"
                                        }} />
                                        {!tags || tags.length === 0 ? <span /> :
                                            <span style={{
                                                fontWeight: "bold",
                                                textAlign: "right",
                                                width: "100%",
                                            }}>
                                                {tags.map((data) => (
                                                    <Chip
                                                        key={data._id}
                                                        label={data.label}
                                                        onDelete={() => handleDeleteTag(data)}
                                                        style={{
                                                            marginTop: "10px",
                                                            marginRight: "25px",
                                                            marginBottom: "10px",
                                                        }}
                                                    />
                                                ))}
                                            </span>
                                        }
                                    </div>
                                </>
                            }

                            <div style={{
                                display: "inline-block",
                                fontWeight: "bold",
                                textAlign: "right",
                                width: "100%",
                            }}>
                                <br />
                                <FormControlLabel control={<Checkbox checked={published} />} onChange={handlePublishChange} label="Publish" />
                                <Button variant="contained" onClick={handleSave}>{newsData && newsData._id ? "Update" : "Save"}</Button>
                            </div>

                            <Divider style={{
                                paddingTop: "10px",
                            }} />
                            <div style={{
                                fontWeight: "bold",
                                paddingTop: "10px",
                                textAlign: "left",
                            }} >
                                Preview
                            </div>
                            <Divider style={{
                                paddingTop: "10px",
                            }} />
                            <div className="news-content" id="target" />
                        </>}
                </Container >
                : <div>Unauthorized</div>
            }
        </Screen >
    );
}

export default PostNews;