import { Container } from '@mui/material';

import { FaFacebookSquare, FaRssSquare, FaTwitterSquare, FaYoutube } from 'react-icons/fa';
import { Link } from "react-router-dom";

import TooltipButton from './tooltip-button'

const API_URI = process.env.REACT_APP_API_URI
const headerUrl = '/images/header.png'
const logoUrl = '/images/logo.png'


function HeaderBlock() {
    return (
        <header>
            <div
                style={{
                    backgroundImage: `url(${headerUrl})`,
                    backgroundRepeat: "repeat-x",
                    borderBottom: "solid #3f98a9",
                    height: "235px",
                }}
            >
                <Link to="/">
                    <img src={logoUrl}
                        alt="Pixel Polish Games"
                        style={{
                            position: "absolute",
                            top: "10px",
                            left: "30px",
                        }}
                    />
                </Link>

                <Container sx={{
                    display: { xs: "block", sm: "inline" },
                    float: "right",
                    margin: "15px",
                    width: { xs: "50px", sm: "200px" },
                }}
                >

                    <a
                        href="http://www.facebook.com/pixelpolishgames/"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TooltipButton icon={FaFacebookSquare} title="Facebook" color="#44a4bb" />
                    </a>

                    <a
                        href="https://twitter.com/PixPolGames"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TooltipButton icon={FaTwitterSquare} title="Twitter" color="#44a4bb" />
                    </a>

                    <a
                        href="https://www.youtube.com/user/PixelPolishGames"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TooltipButton icon={FaYoutube} title="YouTube" color="#44a4bb" />
                    </a>


                    <a
                        href={`${API_URI}rss.xml`}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <TooltipButton icon={FaRssSquare} title="RSS" color="#44a4bb" />
                    </a>
                </Container>
            </div>
        </header >
    );
}

export default HeaderBlock;
