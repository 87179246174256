import { useEffect, useState } from 'react';
import {
    Chip,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow
} from '@mui/material';

import dateFormat from "dateformat";
import { BsFillPlusSquareFill } from 'react-icons/bs';
import { FaCheck, FaEdit } from 'react-icons/fa';
import { ImCross } from "react-icons/im";
import { RiDeleteBin7Fill } from 'react-icons/ri';
import { IconContext } from "react-icons";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";

import api from "../../api"
import { getUser } from "../../stores/user/selectors"
import Screen from '../../components/screen'
import TooltipButton from '../../components/tooltip-button'
import ConfirmDialog from '../../dialogs/confirm-dialog';


function NewsList() {
    const [news, setNews] = useState([]);
    const [page, setPage] = useState(0);
    const [newsPerPage, setNewsPerPage] = useState(15);
    const [confirmDeleteText, setConfirmDeleteText] = useState("");
    const [confirmDeleteId, setConfirmDeleteId] = useState(0);

    const user = useSelector(getUser, shallowEqual)

    async function fetchNews() {
        await api.get("admin/news").then((response) => {
            setNews(response.data);
        });
    }

    useEffect(() => {
        fetchNews();
    }, []);

    const handleDelete = (id) => {
        // Had trouble with the dialog being a single instance, where the last news entry
        // would always be the one that got deleted, when we'd pass these properties into
        // the html, so instead, set all the properties here upon confirmation.
        setConfirmDeleteId(id);
        const title = news.find(newsData => newsData._id === id).title;
        setConfirmDeleteText(`Are you sure you want to delete: ${title}`);
    }

    const handleDeleteConfirmed = async () => {
        setConfirmDeleteId(0);
        await api.delete(`admin/news/${confirmDeleteId}`).then((response) => {
            fetchNews();
        }).catch((error) => {
            console.warn(error);
        });
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeNewsPerPage = (event) => {
        setNewsPerPage(event.target.value);
        setPage(0);
    };

    const columns = [
        { key: 'title', label: 'Title', minWidth: 150 },
        { key: 'author', label: 'Author', minWidth: 80 },
        {
            key: 'postDateTime',
            label: 'Post Time',
            minWidth: 100,
            format: (value) => value ? dateFormat(value, "dddd, mmmm dS, yyyy") : '',
        },
        {
            key: 'tags',
            label: 'Tags',
            format: (tagData) => {
                return (tagData || []).map((tag, i) => {
                    return (<Chip
                        color='secondary'
                        label={tag.label}
                        key={i}
                        style={{
                            margin: "0px 1px",
                            textShadow: "2px 2px #222222",
                        }}
                    />);
                }
                );
            },
        },
        {
            key: 'published',
            label: 'Published',
            minWidth: 50,
            format: (isPublished) => {
                return (isPublished ?
                    <IconContext.Provider value={{
                        color: "green",
                        size: 16,
                    }}>
                        <FaCheck style={{
                            verticalAlign: 'middle',
                            marginLeft: 10,
                        }}
                        />
                    </IconContext.Provider>
                    :
                    <IconContext.Provider value={{
                        color: "red",
                        size: 16,
                    }}>
                        <ImCross style={{
                            verticalAlign: 'middle',
                            marginLeft: 10,
                        }}
                        />
                    </IconContext.Provider>
                )
            },
        },
        {
            key: 'buttons',
            label: '',
            minWidth: 80,
        }
    ];

    return (
        <Screen title="News List">
            {
                user && user.permissions.includes('news') ?
                    <Container>
                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table" >
                                <TableBody>
                                    <TableRow>
                                        <TableCell align="right">
                                            <span style={{ fontWeight: "bold" }}>
                                                Add new content
                                                <Link to={"/admin/post-news"} style={{ textDecoration: "none" }}>
                                                    <TooltipButton
                                                        icon={BsFillPlusSquareFill}
                                                        title="New Post"
                                                        color="#44a4bb"
                                                        padding={5}
                                                        margin={8}
                                                    />
                                                </Link>
                                            </span>
                                        </TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TableContainer>
                            <Table stickyHeader aria-label="sticky table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell
                                                key={column.key}
                                                align={column.align}
                                                style={{ fontWeight: "bold", minWidth: column.minWidth }}
                                            >
                                                {column.label}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {news
                                        .slice(page * newsPerPage, page * newsPerPage + newsPerPage)
                                        .map((newsData) => {
                                            return (
                                                <TableRow hover role="checkbox" tabIndex={-1} key={newsData._id}>
                                                    {columns.map((column) => {
                                                        if (column.key === "buttons") {
                                                            const newsId = newsData._id;
                                                            const url = `/admin/post-news?newsId=${newsId}`;
                                                            return (
                                                                <TableCell key={column.key} align={column.align}>
                                                                    <Link to={url} style={{ textDecoration: "none" }}>
                                                                        <TooltipButton icon={FaEdit} title="Edit" color="#44a4bb" />
                                                                    </Link>

                                                                    <TooltipButton icon={RiDeleteBin7Fill} title="Delete" color="#44a4bb"
                                                                        onClick={() => handleDelete(newsId)}
                                                                    />

                                                                    <ConfirmDialog
                                                                        title="Delete"
                                                                        text={confirmDeleteText}
                                                                        open={confirmDeleteId !== 0}
                                                                        onClose={() => setConfirmDeleteId(0)}
                                                                        onSubmit={handleDeleteConfirmed}
                                                                    />
                                                                </TableCell>
                                                            )
                                                        }
                                                        else {
                                                            const value = newsData[column.key];
                                                            return (
                                                                <TableCell key={column.key} align={column.align}>
                                                                    {column.format
                                                                        ? column.format(value)
                                                                        : value}
                                                                </TableCell>
                                                            );
                                                        }
                                                    })}

                                                </TableRow>
                                            );
                                        })
                                    }
                                </TableBody>
                            </Table>
                        </ TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[15, 30, 50, 100]}
                            component="div"
                            count={news.length}
                            rowsPerPage={newsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeNewsPerPage}
                        />
                    </Container >
                    : <div>Unauthorized</div>
            }
        </Screen >
    );
}

export default NewsList;