import {
  BrowserRouter as Router,
} from "react-router-dom";

import Main from './screens/main'

import './app.css';

function App() {
  return (
    <Router>
      <Main />
    </Router>
  );
}

export default App;
