import { createStore, combineReducers } from "redux";
import NewsReducer from "./news/reducers";
import UserReducer from "./user/reducers";
import UserInitial from "./user/init";


const rootReducer = combineReducers({ NewsReducer, UserReducer });

const initialState = {
    UserReducer: {
        ...UserInitial,
    }
};

export default createStore(rootReducer, initialState);
