import { Alert, Snackbar, Typography } from '@mui/material';


function AlertStack(props) {

    const backgrounds = {
        "error": "#d32f2f",
        "warning": "#ED6C02",
        "info": "#0288d1",
        "success": "#2e7d32",
    }

    return (
        <Snackbar open={props.open} autoHideDuration={2000} onClose={props.onClose}>
            <Alert style={{ background: backgrounds[props.type] }} onClose={props.onClose} severity={props.type} sx={{ width: '100%' }}>
                <Typography color="common.white">
                    {props.text}
                </Typography>
            </Alert>
        </Snackbar>
    );
}

AlertStack.defaultProps = {
    text: "",
    type: "success",
}

export default AlertStack;