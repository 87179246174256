import axios from "axios";

import { CLEAR_USER, SET_USER } from "./types";

export const setUser = (userData) => {
    // Preserve into local storage for refreshes
    localStorage.setItem('user', JSON.stringify(userData));

    // Update the communication layer with the auth token
    axios.defaults.headers.common['Authorization'] = userData.token

    return {
        type: SET_USER,
        user_data: userData,
    };
};

export const clearUser = () => {
    // Clear from local storage aswell.
    localStorage.removeItem('user');

    // Update the communication layer by removing the auth token
    delete axios.defaults.headers.common['Authorization']

    return {
        type: CLEAR_USER,
    };
};
