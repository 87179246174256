import { cloneElement, useState } from 'react';
import { Button, Menu, MenuItem } from '@mui/material';

import { Link } from "react-router-dom";

function DropMenu(props) {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);
    const closeMenu = () => {
        setAnchorEl(null);
    };

    const handleCloseMenu = (e) => {
        if (e && e.currentTarget.localName !== "ul") {
            // Check if we're inside the menu
            const gameMenu = document.getElementById(props.id)
            if (gameMenu) {
                const menu = gameMenu.children[2];
                const menuBoundary = {
                    left: menu.offsetLeft,
                    top: menu.offsetTop,
                    right: menu.offsetLeft + menu.offsetWidth,
                    bottom: menu.offsetTop + menu.offsetHeight,
                };
                if (
                    e.clientX > menuBoundary.left &&
                    e.clientX < menuBoundary.right &&
                    e.clientY < menuBoundary.bottom &&
                    e.clientY > menuBoundary.top
                ) {
                    return;
                }
            }

            // Check if we're inside the main button
            const globalY = window.scrollY + e.currentTarget.getBoundingClientRect().top
            const buttonBoundary = {
                left: e.currentTarget.offsetLeft,
                right: e.currentTarget.offsetLeft + e.currentTarget.offsetWidth,
                bottom: globalY + e.currentTarget.offsetHeight,
                top: globalY,
            };
            if (
                e.clientX > buttonBoundary.left &&
                e.clientX < buttonBoundary.right &&
                e.clientY < buttonBoundary.bottom &&
                e.clientY > buttonBoundary.top
            ) {
                return;
            }
        }

        closeMenu();
    };

    function renderWithLink(item, i) {
        return (
            <Link to={item.url} style={{ textDecoration: "none" }} key={i}>
                <MenuItem onClick={closeMenu} >
                    <Button style={{
                        color: "#44a4bb",
                    }}>
                        {item.text}
                    </Button>
                </MenuItem>
            </Link>
        );
    }

    function renderWithoutLink(item, i) {
        return (
            <MenuItem key={i} onClick={() => {
                if (item.onClick) {
                    item.onClick();
                }
                closeMenu();
            }}>
                <Button style={{
                    color: "#44a4bb",
                }}>
                    {item.text}
                </Button>
            </MenuItem>
        );
    }

    return (
        <>
            {cloneElement(props.children, {
                onClick: () => closeMenu(),
                onMouseOver: (event) => {
                    setAnchorEl(event.currentTarget);
                },
                onMouseLeave: handleCloseMenu,
                onWheel: () => closeMenu(),
            })}
            <Menu
                id={props.id}
                anchorEl={anchorEl}
                open={open}
                onWheel={() => closeMenu()}
                onClose={handleCloseMenu}
                disableScrollLock={true}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "center"
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                    'onMouseLeave': handleCloseMenu,
                }}
            >
                {(props.items || []).map((item, i) => {
                    if (item.url) {
                        return renderWithLink(item, i);
                    } else {
                        return renderWithoutLink(item, i);
                    }
                })
                }

            </Menu>
        </>
    );
}

export default DropMenu;