import { SET_NEWS } from "./types";

const defaults = {
    news: null,
}

function reducer(state = defaults, action) {
    switch (action.type) {
        case SET_NEWS:
            const news_data = action.news_data;
            return {
                ...state,
                news: { ...news_data },
            };
        default:
            return state;
    }
}

export default reducer;
