import axios from "axios";

function initUser() {
    if (localStorage.getItem('user')) {
        let userRecord = JSON.parse(localStorage.getItem('user'));

        // Check for expired credentials
        if (Date.parse(userRecord.expires) < Date.now()) {
            localStorage.removeItem('user');
            return null;
        }

        // Update the communication layer with the auth token
        axios.defaults.headers.common['Authorization'] = userRecord.token

        return userRecord;
    }

    return null;
}


const UserInitial = {
    user: initUser(),
}

export default UserInitial;
