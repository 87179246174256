import { ListItem, ListItemAvatar } from '@mui/material';



function TeamCard(props) {

    return (
        <ListItem alignItems="center">

            <ListItemAvatar >
                {props.image &&
                    <img src={props.image} alt={props.name} />
                }
                <div style={{
                    display: "block",
                    fontWeight: "bold",
                    textAlign: "center",
                    width: "150px",
                }}>
                    {props.name}
                </div>
            </ListItemAvatar>

            <div style={{
                padding: "0px 5px 0px 15px",
            }}>
                <div style={{
                    color: "#80cace",
                    fontWeight: "bold",
                    display: "inline",
                }}>
                    Roles
                </div>
                <div>
                    {props.roles}
                </div>

                <br /><br />

                <div style={{
                    color: "#80cace",
                    fontWeight: "bold",
                    display: "inline",
                }}>
                    Projects
                </div>

                <div>
                    {props.projects}
                </div>

                <br />


                <div style={{
                    color: "#80cace",
                    fontWeight: "bold",
                    display: "inline",
                }}>
                    Details
                </div>


                <div>
                    {props.details}
                </div>
            </div>
        </ListItem>
    );
}

export default TeamCard;
