import { Container } from '@mui/material';

import { Link } from "react-router-dom";

import Screen from '../components/screen'

const lootGrinderUrl = '/images/games/lootgrinder_splash.png';
const monsterEscapeUrl = '/images/games/monsterescape_splash.png';

function Games() {
    return (
        <Screen title="Games">
            <Container>
                <Link to="/games/loot-grinder">
                    <img src={lootGrinderUrl} alt="Loot Grinder" style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                    }} />
                </Link>

                <Link to="/games/monster-escape">
                    <img src={monsterEscapeUrl} alt="Monster Escape" style={{
                        maxWidth: "100%",
                        maxHeight: "100%",
                        objectFit: "contain",
                    }} />
                </Link>
            </Container>
        </Screen>
    );
}

export default Games;